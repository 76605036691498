import axios from "./index";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, {
      headers: { "User-Email": `${data.email}`, Authorization: `${data.token_auth}` },
    });
  };

  static TokenAPI = (data) => {
    return axios.get(`${base}/token-api`, data, {
      headers: { "User-Email": `${data.email}`, Authorization: `${data.token_auth}` },
    });
  };

  static GenerateTokenAPI = (data) => {
    return axios.post(`${base}/token-api-refresh`, data, {
      headers: { "User-Email": `${data.email}`, Authorization: `${data.token_auth}` },
    });
  };
}

let base = "users";

export default AuthApi;
